<template>
  <section class="home">
    <div class="container">
      <div class="row fullheight">
        <div class="col-12 col-md-6 wrapper my-auto">
          <h1 class="display-2">
            Radio <br />
            LeCosimò
          </h1>
          <h1 class="display-2 text-stroke">
            Radio <br />
            LeCosimò
          </h1>
          <p class="lead">
            Scopri le ultime puntate di Radio LeCosimò, la radio ufficiale del
            Liceo Scientifico "De Giorgi" di Lecce.
          </p>
          <video
            controls=""
            autoplay="false"
            name="media"
            height="50"
            class="audio-player mt-4"
          >
            <source
              src="https://dashboard.radio.lecosimo.it/radio/8000/radio.mp3?1615497724"
              type="audio/mpeg"
            />
          </video>
        </div>
        <div class="col-12 col-md-6 my-auto">
          <img class="img-fluid" src="@/assets/img/podcast.png" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
  methods: {
    redirectToSpotify() {
      window.location.href =
        "https://open.spotify.com/show/7BupJTdKGejYPT7k1uF8vR?si=qRIanuqeRdWU13jbOI5osg";
    },
  },
};
</script>

<style scoped lang="scss">
.audio-player {
  border: none !important;
  box-shadow: none !important;
  width: 100%;
}
.wrapper {
  position: relative;
}
.text-stroke {
  position: absolute;
  top: 0.2rem;
  left: 1rem;
  -webkit-text-stroke: 2px white;
  color: transparent;
  z-index: -1;
}
.display-2 {
  font-weight: 700 !important;
}
.btn-primary {
  border-radius: 0;
  background-color: #191919;
  border: none;
}
.btn-primary:hover {
  background-color: #ffb100;
}
body {
  background-color: #c44636;
}
.iframe-stroke {
  position: absolute;
  border: 2px solid white;
  height: 232px;
  width: calc(100% - 40px);
  right: 0;
  top: 10px;
  z-index: -1;
  border-radius: 0.5rem;
}
</style>
